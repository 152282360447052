
import { defineComponent, onMounted, ref } from 'vue'
import { ImagePreview } from 'vant'
import { openFileView } from '@/utils/native-app'
import { queryFn } from '@/graphql/index'
import * as storage from '@/utils/storage'
import { useRoute, useRouter } from 'vue-router'

const openAccountTypeMap: any = {
  openAccount: 1, // 普通开户
  openImmigrantAccount: 9 // 移民开户
}

export default defineComponent({
  setup () {
    const route = useRoute()
    const router = useRouter()

    const reasonList = ref([
      { icon: 'icon-zhaopian', name: '身份证正面照-人像' },
      { icon: 'icon-dianziqianming', name: '签名' },
      { icon: 'icon-pdf', name: '资产凭证1' },
      { icon: 'icon-wenjian', name: '开户同意书' }
    ])
    const rejectContent = ref([])
    const rejectFiles: any = ref([])
    const rejectContentOther = ref('')

    onMounted(async () => {
      const formType = '' + route.query.type || storage.get('formType') || 'openAccount'
      const res = await queryFn('customerOpenStatus', {
        openAccountType: openAccountTypeMap[formType] || 1
      })
      const { data } = res
      rejectContent.value = data.rejectContent
      rejectFiles.value = data.rejectFiles
      rejectContentOther.value = data.rejectContentOther
    })

    const getIcon = (item: { imageType: number; fileExtName: string }) => {
      switch (item.imageType) {
        case 101:
        // falls through
        case 102:
        // falls through
        case 103:
        // falls through
        case 104:
        // falls through
        case 105:
        // falls through
        case 106:
        // falls through
        case 107:
        // falls through
        case 108:
        // falls through
        case 109:
        // falls through
        case 110:
        // falls through
        case 111:
          return 'icon-zhaopian'
        case 301:
          return 'icon-dianziqianming'
        case 401:
          return 'icon-wenjian'
        // case 801:
        //   if (item.fileExtName === '.pdf') {
        //     return 'icon-pdf';
        //   }
        //   return 'icon-zhaopian';
        default:
          if (item.fileExtName === '.pdf') {
            return 'icon-pdf'
          }
          return 'icon-zhaopian'
      }
    }
    const toUrl = (item: { fileExtName: string; remoteUrl: string; fileName: any }) => {
      if (item.fileExtName === '.pdf') {
        const params = {
          fileType: 'pdf',
          fileUrl: item.remoteUrl,
          title: item.fileName
        }
        openFileView(params)
      } else {
        ImagePreview({
          images: [item.remoteUrl],
          closeable: true
        })
      }
    }

    const resetFormView = () => {
      router.push({ name: 'id-type', query: { type: route.query.type } })
    }
    return {
      reasonList,
      rejectFiles,
      rejectContent,
      rejectContentOther,
      toUrl,
      getIcon,
      resetFormView
    }
  }
})
